import { arrayMutations, objectMutations } from '@/store/shared';
import { TaskStatus, TaskType, TaskVerificationType } from '@/constants';

export default {
	namespaced: true,
	state: {
		applicationId: 0,
		applicationToken: '',
		taskId: 0,
		type: TaskType.NONE,
		status: TaskStatus.NONE,
		verificationType: TaskVerificationType.NONE,
		data: '',
        moduleName: '',
        taskTitle: ''
	},
	mutations: {
		...arrayMutations,
		...objectMutations,
		/**
		 *
		 * @param {Task} state Task object from Vue state
		 */
		resetContainedState(state) {
			state.applicationId = 0;
			state.applicationToken = '';
			state.taskId = 0;
			state.type = TaskType.NONE;
			state.status = TaskStatus.NONE;
			state.verificationType = TaskVerificationType.NONE;
			state.data = '';
            state.moduleName = '';
            state.taskTitle = '';
		},
		/**
		 * @param {any} state Task object from Vue state
		 * @param {Task} task Local Task object
		 */
		setTaskData(state, task) {
			state.applicationId = task.applicationId;
			state.applicationToken = task.applicationToken;
			state.taskId = task.taskId;
			state.type = task.type;
			state.status = task.status;
			state.verificationType = task.verificationType;
			state.data = task.data;
			state.moduleName = task.moduleName;
		}
	},
	actions: {
		/**
		 * @param {*} commit Vue store
		 */
		resetState({ commit }) {
			commit('resetContainedState');
		},
		/**
		 * @param {*} commit Vue store
		 * @param {Task} task Task object to commit
		 */
		setTask({ commit }, task) {
			commit('setTaskData', task);
		}
	}
};
