import { arrayMutations, objectMutations } from '@/store/shared';
import { StipulationStatus, StipulationType } from '@/constants';

export default {

    namespaced: true,
    state: {

        applicationId: 0,
        applicationToken: '',
        stipulationId: 0,
        stipulationToken: '',
        title: '',
        type: StipulationType.NONE,
        status: StipulationStatus.NONE,
        moduleName: ''

    },
    mutations: {

        ...arrayMutations,
        ...objectMutations,
        resetContainedState(state) {
            state.applicationId = 0;
            state.applicationToken = '';
            state.stipulationId = 0;
            state.stipulationToken = '';
            state.title = '';
            state.type = StipulationType.NONE;
            state.status = StipulationStatus.NONE;
            state.moduleName = '';
        },
        setStipulationData(state, { applicationId, applicationToken, stipulationId, stipulationToken, title, type, status, moduleName }) {
            state.applicationId = applicationId;
            state.applicationToken = applicationToken;
            state.stipulationId = stipulationId;
            state.stipulationToken = stipulationToken;
            state.title = title;
            state.type = type;
            state.status = status;
            state.moduleName = moduleName;
        }
    },
    actions: {

        resetState({ commit }) {
            commit('resetContainedState');
        },
        setStipulation({ commit }, { applicationId, applicationToken, stipulationId, stipulationToken, title, type, status, moduleName }) {

            commit('setStipulationData', {

                applicationId,
                applicationToken,
                stipulationId,
                stipulationToken,
                title,
                type,
                status,
                moduleName

            });

        }
    },
    getters: {

    }

};