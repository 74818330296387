import { arrayMutations, objectMutations } from '@/store/shared';

export default {
	namespaced: true,
	state: {
		applicationId: 0,
		applicationToken: '',
		noteId: 0,
		noteText: '',
		noteCreatedDate: '',
		data: '',
		moduleName: ''
	},
	mutations: {
		...arrayMutations,
		...objectMutations,
		/**
		 *
		 * @param {Note} state Note object from Vue state
		 */
		resetContainedState(state) {
			state.applicationId = 0;
			state.applicationToken = '';
			state.noteId = 0;
			state.noteText = '';
			state.noteCreatedDate = '';
			state.data = '';
			state.moduleName = '';
		},
		/**
		 * @param {any} state Note object from Vue state
		 * @param {Note} note Local Note object
		 */
		setNoteData(state, note) {
			state.applicationId = note.applicationId;
			state.applicationToken = note.applicationToken;
			state.noteId = note.id;
			state.noteText = note.noteText;
			state.noteCreatedDate = note.createdDate;
			state.data = note.data;
			state.moduleName = note.moduleName;
		}
	},
	actions: {
		/**
		 * @param {*} commit Vue store
		 */
		resetState({ commit }) {
			commit('resetContainedState');
		},
		/**
		 * @param {*} commit Vue store
		 * @param {Note} note Note object to commit
		 */
		setNote({ commit }, note) {
			commit('setNoteData', note);
		}
	}
};
