import {
    arrayMutations,
    objectMutations,
    applicationActions,
    applicationMutations,
    applicationState,
    applicationGetters,
    resetApplicationState

} from '@/store/shared';

export default {

    namespaced: true,
    state: {

        ...applicationState

    },
    mutations: {

        ...arrayMutations,
        ...objectMutations,
        ...resetApplicationState,
        ...applicationMutations

    },
    actions: {

        ...applicationActions

    },
    getters: {

        ...applicationGetters

    }

};
