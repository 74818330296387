<template>
	<div v-if="!loading" class="application-page">
		<Portal to="hero">
			<StsTitle class="is-desktop" isSmall>
				<div v-html="welcomeMessage"></div>
			</StsTitle>
		</Portal>

		<StsTitle class="is-mobile" style="color: var(--primary); margin-bottom: 1.5rem; text-shadow: none" isSmall>
			<div v-html="welcomeMessage"></div>
		</StsTitle>

		<StsTabs noShadow noGap activeTabColor="var(--white)" inactiveTabColor="var(--bodyCopy-25)" :desktopBreakpoints="[2, 1]" @select="handleTabClick">
			<StsTab
				v-for="(application, index) in applicant.applications"
				:id="application.moduleName"
				:key="`tab-${index}`"
				:name="application.tabTitle"
				:selected="application.isActive"
			>
				<ApplicationTab :moduleName="application.moduleName" />
			</StsTab>
		</StsTabs>
	</div>
</template>

<script>
	import { StsTab, StsTabs, StsTitle } from '@/components/SwitchThink';
	import { LoanDecision, LoanStatus } from '@/constants';

	import showSpinner from '@/mixins/showSpinner';
	import { applicationTemplate } from '@/store/modules/templates';
	import { toastConfig } from '@/utils';
	import _cloneDeep from 'lodash.clonedeep';
	import { Portal } from 'portal-vue';
	import { createToastInterface } from 'vue-toastification';
	import { mapActions, mapGetters } from 'vuex';

	const toast = createToastInterface(toastConfig);

	export default {
		name: 'Application',
		components: {
			Portal,
			StsTabs,
			StsTab,
			StsTitle,
			ApplicationTab: () => import('@/views/Partials/ApplicationTab')
		},
		mixins: [showSpinner],
		props: {
			applicant: {
				type: Object,
				required: true
			}
		},
		computed: {
			...mapGetters('applicant', ['welcomeMessage', 'applicantToken']),
			loanDecision() {
				return {
					DECLINED: LoanDecision.DECLINED
				};
			},
			loanStatus() {
				return {
					OFFER_EXPIRED: LoanStatus.OFFER_EXPIRED
				};
			}
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

				if (this.applicantToken) {
					await this.getApplicant();
					await this.loadApplications();

					const applications = this.applicant.applications;
					if (applications.length > 0) {
						let firstRun = true;

						for (let i = 0; i < applications.length; i++) {
							const item = _cloneDeep(applications[i]);

							if (firstRun) {
								item.isActive = true;
								firstRun = false;
							}

							if (this.$store.hasModule(item.moduleName)) {
								await this.$store.dispatch(`${item.moduleName}/setApplication`, item.id);
								item.application = await this.$store.dispatch(`${item.moduleName}/getApplication`);
							} else {
								await Promise.all([
									this.$store.registerModule(item.moduleName, _cloneDeep(applicationTemplate)),
									this.$store.dispatch(`${item.moduleName}/setApplication`, item.id)
								]);
								item.application = await this.$store.dispatch(`${item.moduleName}/getApplication`);
							}

							item.tabTitle = await this.$store.dispatch(`${item.moduleName}/getTabTitle`);

							await this.$store.dispatch('applicant/updateApplication', item);
						}

						// If at least one application is declined and every other application is either declined or expired, we want to redirect to the declined loan page.
						applications.forEach(app => {
							app.isDeclined = app.application.loanDecision === this.loanDecision.DECLINED;
							app.isExpired = app.application.loanStatus === this.loanStatus.OFFER_EXPIRED;
						});

						if (applications.some(x => x.isDeclined)) {
							if (applications.every(x => x.isDeclined || x.isExpired)) {
								return this.$router.push(this.$constructUrl('DeclinedLoan'));
							}
						}
					} else {
						return this.$router.push(this.$constructUrl('InactiveLoan'));
					}
				}
			} catch (error) {
				return toast.error(error);
			} finally {
				this.showSpinner({ submitting: false });
			}
		},
		methods: {
			...mapActions('applicant', [
				'getApplicant',
				'loadApplications',
				'setActiveApplication',
				'setActiveApplicationIsDeclined',
				'setActiveApplicationProductTypeID'
			]),
			async handleTabClick(applicationName) {
				this.setActiveApplication(applicationName);
				const productTypeID = await this.$store.dispatch(`${applicationName}/getProductType`);
				this.setActiveApplicationProductTypeID(productTypeID);
				const activeApplication = this.applicant.activeApplication;
				this.setActiveApplicationIsDeclined(this.applicant.applications[activeApplication].isDeclined);
			}
		}
	};
</script>

<style>
    main {
        background-color: var(--white);
    }
</style>